import { Drawer as AntdDrawer, Button } from 'antd'
import { rem, rgba } from 'polished'
import styled from 'styled-components'

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-body {
    padding: 16px;
  }

  .ant-drawer-title {
    color: ${({ theme }) => theme.colors.text};

    :not(:disabled):hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .ant-drawer-extra {
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.brand};

    :not(:disabled):hover {
      color: ${({ theme }) => rgba(theme.colors.brand, 0.8)};
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: end;

    .ant-btn-default {
      :not(:disabled):hover {
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }
`

export const DrawerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  h3 {
    color: #434343;
    font-weight: 700;
    font-size: 18px;

    :hover {
      color: #434343 !important;
    }
  }

  span {
    color: #8e8e8e;
    font-size: 16px;
    font-weight: 400;

    :hover {
      color: #8e8e8e !important;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ClearAllButton = styled(Button)`
  margin-left: auto;
  padding: 0;
`

export const CloseButton = styled(Button)`
  display: flex;
  flex-direction: row-reverse;
  padding: 0;
`

export const NotificationCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  > span {
    color: #8e8e8e;
    font-size: 14px;
    font-weight: 400;
  }
`

export const NotificationMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const NotificationMessage = styled.strong`
  color: #434343;
  font-weight: 600;
  font-size: 14px;
  /* margin: 8px 0 16px; */
`
export const BGName = styled(Button)`
  display: flex;

  font-weight: 700;
  font-size: ${rem(12)};
  padding: 0;
  white-space: normal;
  text-align: left;
  line-height: normal;
  height: 50px;

  color: ${({ theme }) => theme.colors.darkGreen};
`

export const NotificationWarning = styled.span`
  font-size: ${rem(14)};
  color: #ff4d4d;
`
