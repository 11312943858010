import { ImagePackTable } from '../../Table/ImagePackTable'
import * as S from './styles'

import { BgFileUploadData, SkuFile } from '@/features/BG'

interface SkuFileProps {
  data: SkuFile[]
  setImagePackData: React.Dispatch<React.SetStateAction<BgFileUploadData[]>>
  imagePackData: BgFileUploadData[]
}

export function ImagePack({ data, setImagePackData, imagePackData }: SkuFileProps) {
  const files: SkuFile[] = data
    .sort((a, b) => Number(a.order) - Number(b.order))
    .map((file: SkuFile, i) => {
      return { ...file, order: i.toString() }
    })

  return (
    <S.Container>
      <S.Disclaimer>
        Selecione abaixo a imagem principal que servirá como vitrine e em seguida, faça a ordenação
        das demais, arrastando-as para a sequência desejada.
      </S.Disclaimer>

      <ImagePackTable
        data={files}
        setImagePackData={setImagePackData}
        imagePackData={imagePackData}
      />
    </S.Container>
  )
}
