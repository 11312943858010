import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'
import { Button, DrawerProps, Empty, Skeleton } from 'antd'

import * as S from './styles'

import {
  formatDate,
  ListResponse,
  //  useAuth
} from '@/common'
import { LoadingSpinner } from '@/components'
import { TitleTabs } from '@/components/Layout/PageLayout'
import { BgStatusTags } from '@/features/BG'
import {
  useClearAllNotificationsMutation,
  useReadNotificationMutation,
  type Notification,
} from '@/features/notification'

interface NotificationsDrawer extends DrawerProps {
  isOpen: boolean
  onClose: () => void
  data: Array<ListResponse<Notification, 'notifications'>>
  hasNextPage: boolean | undefined
  isInitialLoading: boolean
  isLoadingMoreNotifications: boolean
  fetchNextPage: () => void
}

type TabKeys = 'notifications' | 'config'

// const DrawerTitle = () => (
//   <S.DrawerTitle>
//     <h3>Notificações</h3>
//     <span>-</span>
//     <span>Cadastro de Produtos</span>
//   </S.DrawerTitle>
// )

export const NotificationsDrawer = ({
  isOpen,
  onClose,
  data,
  hasNextPage,
  isInitialLoading,
  isLoadingMoreNotifications,
  fetchNextPage,
  ...rest
}: NotificationsDrawer) => {
  // const { userRoles } = useAuth()
  const navigate = useNavigate()
  const hasData = data.some((page) => page.total > 0)

  const [tabActiveKey, setTabActiveKey] = useState<TabKeys>('notifications')
  const [readingNotificationId, setReadingNotificationId] = useState<string | null>(null)

  const readNotificationMutation = useReadNotificationMutation()
  const clearAllNotificationsMutation = useClearAllNotificationsMutation()

  async function handleReadNotification(notificationId: string) {
    try {
      setReadingNotificationId(notificationId)

      await readNotificationMutation.mutateAsync(notificationId)

      setReadingNotificationId(null)
    } catch (err) {
      console.error('Error reading notification', err)
      setReadingNotificationId(null)
    }
  }

  async function handleOpenNotification(notification: Notification) {
    handleReadNotification(notification.id)

    return navigate(`/product/${notification.product_id}`)
  }

  async function handleClearAllNotifications() {
    try {
      await clearAllNotificationsMutation.mutateAsync()

      onClose()
    } catch (err) {
      console.error('Error clearing all notifications', err)
    }
  }

  const notificationTabItems = [
    {
      key: 'notifications',
      label: 'Notificações',
      content: (
        <>
          {isInitialLoading ? (
            <Skeleton active />
          ) : (
            <>
              {!hasData ? (
                <Empty description="Nenhuma notificação" />
              ) : (
                <>
                  <S.ClearAllButton type="link" onClick={() => handleClearAllNotifications()}>
                    Limpar todas notificações
                  </S.ClearAllButton>

                  {data.map((page) => {
                    return page.notifications.map((notification) => {
                      // const canNavigate = notification.group in userRoles || '*' in userRoles
                      const cardTitle =
                        notification.description.notification_type === 'STATUS'
                          ? 'Atualização de status'
                          : notification.description.notification_type === 'SLA'
                          ? 'Alerta de atraso'
                          : 'Alerta de estoque'

                      return (
                        <S.NotificationCard key={notification.id}>
                          <S.CardRow>
                            <span>{cardTitle}</span>

                            <S.CloseButton
                              type="link"
                              onClick={() => handleReadNotification(notification.id)}
                              disabled={clearAllNotificationsMutation.isPending}
                            >
                              {readNotificationMutation.isPending &&
                              readingNotificationId === notification.id ? (
                                <LoadingSpinner />
                              ) : (
                                <CloseOutlined />
                              )}
                            </S.CloseButton>
                          </S.CardRow>

                          <S.CardRow>
                            <S.NotificationMessageContainer>
                              <S.NotificationMessage>
                                {notification.description.name}
                              </S.NotificationMessage>

                              {notification.description.notification_type === 'SLA' && (
                                <span>
                                  Dias excedidos no status:{' '}
                                  <S.NotificationWarning>
                                    {notification.description.days_exceeded}
                                  </S.NotificationWarning>
                                </span>
                              )}

                              {notification.description.notification_type === 'STOCK' && (
                                <span>
                                  Estoque {notification.description.platform}:{' '}
                                  <S.NotificationWarning>
                                    {notification.description.stock_quantity}
                                  </S.NotificationWarning>
                                </span>
                              )}

                              <BgStatusTags status={notification.description.status as any} />
                            </S.NotificationMessageContainer>
                          </S.CardRow>

                          <S.CardRow>
                            <span>{formatDate({ date: new Date().toString() })}</span>

                            <Button
                              type="primary"
                              disabled={clearAllNotificationsMutation.isPending}
                              onClick={() => handleOpenNotification(notification)}
                            >
                              Ver BG
                            </Button>
                          </S.CardRow>
                        </S.NotificationCard>
                      )
                    })
                  })}

                  {isLoadingMoreNotifications ? (
                    <LoadingSpinner />
                  ) : hasNextPage ? (
                    <Button onClick={fetchNextPage} type="link" block>
                      Carregar mais
                    </Button>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      ),
    },
  ]

  return (
    <S.Drawer
      open={isOpen}
      title={
        <TitleTabs
          items={notificationTabItems}
          defaultActiveKey="notifications"
          onChange={(key) => setTabActiveKey(key as TabKeys)}
        />
      }
      placement="right"
      width={350}
      closable={false}
      extra={<CloseOutlined onClick={onClose} />}
      {...rest}
    >
      <S.Container>
        {notificationTabItems.map((item) => {
          if (item.key === tabActiveKey) {
            return <Fragment key={item.key}>{item.content}</Fragment>
          }

          return null
        })}
      </S.Container>
    </S.Drawer>
  )
}
