import { Select } from 'antd'

import * as S from './styles'

interface CustomPaginationProps {
  page: number
  pageSize: number
  totalItems: number
  totalPages: number
  isLoading: boolean
  deselecting?: boolean
  changePageValue: (page: number, pageSize: number) => void
  scrollToTop?: boolean
  showPageJumper?: boolean
}

export const CustomPagination = ({
  page,
  pageSize,
  totalItems,
  totalPages,
  isLoading,
  deselecting,
  changePageValue,
  scrollToTop,
  showPageJumper = true,
}: CustomPaginationProps) => {
  function handlePagination(page: number, pageSize: number) {
    if (page > 0) {
      const pageNumber = page > totalPages ? totalPages : page

      changePageValue(pageNumber, pageSize)

      if (scrollToTop) {
        window.scrollTo(0, 0)
      }
    }
  }

  return (
    <S.CustomPaginationContainer>
      <S.Pagination
        size="small"
        current={page}
        defaultCurrent={page}
        total={totalItems}
        pageSize={pageSize}
        defaultPageSize={pageSize}
        disabled={isLoading}
        showQuickJumper={showPageJumper}
        showSizeChanger={false}
        onChange={(page, perPage) => handlePagination(page, perPage)}
        showTotal={(total, range) => {
          if (isLoading) return <></>

          return (
            <S.PaginationRange>
              {!deselecting && (
                <Select
                  size="small"
                  value={pageSize}
                  options={[
                    { label: '10 / página', value: 10 },
                    { label: '20 / página', value: 20 },
                    { label: '50 / página', value: 50 },
                    { label: '100 / página', value: 100 },
                  ]}
                  onChange={(newPageSize) => handlePagination(page, newPageSize)}
                />
              )}
              <span>
                Exibindo {range[0]} - {range[1]} do total de <S.TotalPages>{total}</S.TotalPages>{' '}
                registros
              </span>
            </S.PaginationRange>
          )
        }}
        locale={{
          items_per_page: '/ página',
          jump_to: 'Ir para página',
          jump_to_confirm: 'Confirma ir para',
          page: '',
          prev_page: 'Página anterior',
          next_page: 'Próxima página',
        }}
      />
    </S.CustomPaginationContainer>
  )
}
