import { useState } from 'react'

import { GetProp, UploadFile, UploadProps } from 'antd'

import { getBase64 } from '../utils/getBase64'
import { useToggle } from './useToggle'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const useUploadImagePreview = () => {
  const [previewOpen, togglePreview] = useToggle(false)
  const [previewImage, setPreviewImage] = useState('')

  async function handlePreview(file: UploadFile) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    togglePreview()
  }

  return {
    previewOpen,
    previewImage,
    handlePreview,
    togglePreview,
  }
}
