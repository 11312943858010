import { useMutation } from '@tanstack/react-query'

import { DEFAULT_SLA_DAYS } from '../constants'
import { AlertSettings } from '../types'

import { api } from '@/services'

export const useCreateAlertSettingsMutation = () => {
  return useMutation({
    mutationFn: async (data: AlertSettings) => {
      return api.post('/settings', {
        ...data,
        sla_days: data.sla_days || DEFAULT_SLA_DAYS,
      })
    },
  })
}
