import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import { BomSpreadsheetFilters } from '../components/Drawer/SpecificationsScrapingDrawer/Bom'
import type {
  Timeline,
  Fabricantes,
  Product,
  QuantityByStatus,
  BgTemplate,
  BomSpecification,
  ScrapingSpecification,
  FilterAndIconsResponse,
  SupportFilesResponse,
  BomDetails,
  BrandResponse,
} from '../types'

import type { BGProductFilters } from '@/app'
import { generateSearchParams, ListResponse } from '@/common'
import { api } from '@/services'

export const bgQueries = {
  all: () => ['bgs'],
  // --- Dashboard list ---
  lists: () => [...bgQueries.all(), 'list'],
  list: (filters: BGProductFilters) =>
    queryOptions({
      queryKey: [...bgQueries.lists(), filters],
      queryFn: () => fetchBgList(filters),
      placeholderData: keepPreviousData,
    }),
  //  --- Product Page ---
  details: () => [...bgQueries.all(), 'detail'],
  detail: (bgId: string) =>
    queryOptions({
      queryKey: [...bgQueries.details(), bgId],
      queryFn: () => fetchBgDetail(bgId),
      placeholderData: keepPreviousData,
    }),
  brands: () =>
    queryOptions({
      queryKey: [...bgQueries.all(), 'brands'],
      queryFn: () => fetchBgBrands(),
      placeholderData: keepPreviousData,
    }),
  //  --- Status cards ---
  quantityByStatus: () =>
    queryOptions({
      queryKey: [...bgQueries.all(), 'quantity_by_status'],
      queryFn: () => fetchBgQuantityByStatus(),
    }),
  //  --- Timeline ---
  timelines: () => [...bgQueries.all(), 'timeline'],
  timeline: (bgId: string) =>
    queryOptions({
      queryKey: [...bgQueries.timelines(), bgId],
      queryFn: () => fetchBgTimeline(bgId),
      placeholderData: keepPreviousData,
    }),
  //  --- Product filters and icons (publication) ---
  filtersAndIcons: (categoryVtexId: number) =>
    queryOptions({
      queryKey: [...bgQueries.all(), 'filters-and-icons', categoryVtexId],
      queryFn: () => fetchBgFiltersAndIcons(categoryVtexId),
      placeholderData: keepPreviousData,
    }),
  //  --- Product templates (BG creation) ---
  templates: () => [...bgQueries.all(), 'templates'],
  templateList: (productTypeVtexId: number) =>
    queryOptions({
      queryKey: [...bgQueries.templates(), productTypeVtexId],
      queryFn: () => fetchBgTemplateList(productTypeVtexId),
      placeholderData: keepPreviousData,
    }),
  emptyTemplate: (productTypeVtexId: number) =>
    queryOptions({
      queryKey: [...bgQueries.templates(), 'empty', productTypeVtexId],
      queryFn: () => fetchBgEmptyTemplate(productTypeVtexId),
      placeholderData: keepPreviousData,
    }),
  templateDetail: (model: string) =>
    queryOptions({
      queryKey: [...bgQueries.templates(), 'detail', model],
      queryFn: () => fetchBgTemplateDetail(model),
      placeholderData: keepPreviousData,
    }),
  //  --- Product specifications help (BG creation) ---
  specifications: () => [...bgQueries.all(), 'specifications'],
  //? bom: (model: string) =>
  //   queryOptions({
  //     queryKey: [...bgQueries.specifications(), 'bom', model],
  //     queryFn: () => fetchBgBomSpecifications(model),
  //     placeholderData: keepPreviousData,
  //   }),
  bom: (filter: BomSpreadsheetFilters) =>
    queryOptions({
      queryKey: [...bgQueries.specifications(), 'bom', filter],
      queryFn: () => fetchBgBomSpecifications(filter),
      placeholderData: keepPreviousData,
    }),
  bomImportStatus: (filename: string) =>
    queryOptions({
      queryKey: [...bgQueries.specifications(), 'bom-import-status', filename],
      queryFn: () => fetchBomImportStatus(filename),
      placeholderData: keepPreviousData,
    }),
  scraping: (scrapingType: Fabricantes, search: string) =>
    queryOptions({
      queryKey: [...bgQueries.specifications(), 'scraping', scrapingType, search],
      queryFn: () => fetchBgScrapingSpecifications(scrapingType, search),
      placeholderData: keepPreviousData,
    }),
  bgFamily: (productType: string, family: string) =>
    queryOptions({
      queryKey: [...bgQueries.specifications(), 'family', productType, family],
      queryFn: () => fetchBgFamily(productType, family),
      placeholderData: keepPreviousData,
    }),
  bgModel: (productType: string, model: string) =>
    queryOptions({
      queryKey: [...bgQueries.specifications(), 'model', productType, model],
      queryFn: () => fetchBgModel(productType, model),
      placeholderData: keepPreviousData,
    }),
  // --- Product support files ---
  bgFiles: () => [...bgQueries.all(), 'files'],
  supportFiles: (bgId: string) =>
    queryOptions({
      queryKey: [...bgQueries.bgFiles(), bgId],
      queryFn: () => fetchSupportFiles(bgId),
      placeholderData: keepPreviousData,
    }),
}

const fetchBgList = async (filters: BGProductFilters) => {
  const { page, logs_per_page, ...activeFilters } = filters

  const url = generateSearchParams(activeFilters)

  const { data } = await api.get<ListResponse<Product, 'products'>>(
    `/products?per_page=${logs_per_page}&page=${page}&${url}`,
  )

  return data
}

const fetchBgDetail = async (bgId: string) => {
  const { data } = await api.get<Product>(`/products/${bgId}`)

  return data
}

const fetchBgBrands = async () => {
  const { data } = await api.get<BrandResponse[]>('/vtex/brands')

  return data
}

const fetchBgQuantityByStatus = async () => {
  const { data } = await api.get<QuantityByStatus[]>('/products/quantity_by_status')

  return data
}

export const fetchBgTimeline = async (bgId: string) => {
  const { data } = await api.get<Timeline[]>(`/timelines/${bgId}`)

  return data
}

export const fetchBgFiltersAndIcons = async (categoryVtexId: number) => {
  const { data } = await api.get<FilterAndIconsResponse>(
    `/specifications/filter_and_icons/${categoryVtexId}`,
  )

  return data
}

export const fetchBgTemplateList = async (productTypeVtexId: number) => {
  const { data } = await api.get<ListResponse<BgTemplate, 'templates'>>(
    `/templates?product_type_vtex_id=${productTypeVtexId}`,
  )

  return data
}

export const fetchBgEmptyTemplate = async (productTypeVtexId: number) => {
  const { data } = await api.get<Record<string, string>>(
    `/product_types/${productTypeVtexId}/empty_template`,
  )

  return data
}

export const fetchBgTemplateDetail = async (model: string) => {
  const { data } = await api.get<BgTemplate>(`/templates/${model}`)

  return data
}

//? export const fetchBgBomSpecifications = async (model: string) => {
//   const { data } = await api.get<BomSpecification>(`/bom/${model}`)

//   return data
// }

export const fetchBgBomSpecifications = async (filters: BomSpreadsheetFilters) => {
  const { page, per_page, ...activeFilters } = filters

  const url = generateSearchParams(activeFilters)
  const { data } = await api.get<BomSpecification>(`/bom?per_page=${per_page}&page=${page}&${url}`)

  return data
}

export const fetchBomImportStatus = async (filename: string) => {
  const { data } = await api.get<BomDetails>(`/bom/details?s3_filename=${filename}`)

  return data
}

export const fetchBgScrapingSpecifications = async (scrapingType: Fabricantes, search: string) => {
  const { data } = await api.get<ScrapingSpecification>(`/scraping?${scrapingType}=${search}`)

  return data
}

export const fetchBgFamily = async (productType: string, family: string) => {
  const { data } = await api.get<string[]>(
    `/scraping/families?product_type=${productType}&family=${family}`,
  )

  return data
}

export const fetchBgModel = async (productType: string, model: string) => {
  const { data } = await api.get<string[]>(
    `/scraping/models?product_type=${productType}&model=${model}`,
  )

  return data
}

export const fetchSupportFiles = async (bgId: string) => {
  const { data } = await api.get<SupportFilesResponse>(`/products/support_files/${bgId}`)

  return data
}
