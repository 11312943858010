import styled from 'styled-components'

export const Container = styled.div`
  .ant-table table {
    font-size: 14px;
  }

  .ant-table th {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-table td {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0px;
    background-color: #ededed;
    color: ${(props) => props.theme.customTokens.text.tableHeaderText};
    font-weight: 400;
    font-size: 14px;
  }

  .ant-table-wrapper .ant-table-container table > thead {
    tr:first-child > *:first-child {
      border-top-left-radius: 4px !important;
    }

    tr:first-child > *:last-child {
      border-top-right-radius: 4px !important;
    }
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  .ant-table-cell {
    padding: 14px !important;
  }
  .ant-table-summary {
    display: table-header-group;
  }

  .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #eaf0f9;
  }

  .ant-table-wrapper .ant-table-column-sort {
    background: none;
  }

  .ant-table-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0;
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: #efefef;
  }
`
