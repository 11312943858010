import { CheckCircleOutlined, DeleteOutlined, HolderOutlined } from '@ant-design/icons'
import { rem } from 'polished'
import styled from 'styled-components'

export const ShowcaseContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .ant-image-img {
    width: 62px;
    height: 52px;
    object-fit: cover;
  }
`
export const DraggableIcon = styled(HolderOutlined)`
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.colors.link};
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TooltipIcon = styled.div`
  padding: 1px;
  font-size: 14px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999999px;

  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.white};
`

export const InputName = styled.span``

export const DeleteImageButton = styled(DeleteOutlined)`
  cursor: pointer;
  font-size: 16px;
  color: #ff4d4d;
`
export const UploadContainer = styled.div`
  padding: 8px 24px;

  img {
    cursor: pointer;
  }
`
export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const LoadingStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(50)};
  width: ${rem(100)};
`
