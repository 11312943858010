import { useState } from 'react'

import { Button, Form, Tabs } from 'antd'

import { StatusAlertForm, StockAlertForm } from '../Form'

import { BaseModalProps } from '@/common'
import { BaseModal } from '@/components'

interface AlertConfigurationModalProps extends BaseModalProps {}

type AlertTabs = 'status' | 'stock'

export const AlertConfigurationModal = ({ isOpen, onClose }: AlertConfigurationModalProps) => {
  const [activeTab, setActiveTab] = useState<AlertTabs>('status')
  const [isStockFormTouched, setIsStockFormTouched] = useState(false)
  const [isStatusFormTouched, setIsStatusFormTouched] = useState(false)
  const [isMutating, setIsMutating] = useState(false)
  const [form] = Form.useForm()

  const tabItems = [
    {
      key: 'status',
      label: 'Status',
      children: (
        <StatusAlertForm
          form={form}
          setIsFormTouched={setIsStatusFormTouched}
          setIsMutating={setIsMutating}
        />
      ),
    },
    {
      key: 'stock',
      label: 'Estoque',
      children: (
        <StockAlertForm
          form={form}
          setIsFormTouched={setIsStockFormTouched}
          setIsMutating={setIsMutating}
        />
      ),
    },
  ]

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Configuração de alertas"
      width={720}
      classNames={{
        body: 'alert-modal-body',
      }}
      footer={
        <>
          <Button type="link" htmlType="button" onClick={onClose} disabled={isMutating}>
            Cancelar
          </Button>

          <Button
            type="primary"
            htmlType="button"
            disabled={activeTab === 'status' ? !isStatusFormTouched : !isStockFormTouched}
            loading={isMutating}
            onClick={() => form.submit()}
          >
            Salvar
          </Button>
        </>
      }
    >
      <Tabs
        defaultActiveKey="status"
        tabPosition={'left'}
        style={{ height: 300 }}
        items={tabItems}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as AlertTabs)}
      />
    </BaseModal>
  )
}
