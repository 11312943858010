import { GetProp, UploadProps } from 'antd'
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export function getBase64(file: FileType): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}
