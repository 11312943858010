import { queryOptions } from '@tanstack/react-query'

import { AlertSettingsResponse } from '@/features/alerts'
import { api } from '@/services'

export const alertQueries = {
  all: () => ['alerts'],
  alertSettings: () =>
    queryOptions({
      queryKey: [...alertQueries.all(), 'settings'],
      queryFn: () => fetchAlertSettings(),
    }),
}

export const fetchAlertSettings = async () => {
  const { data } = await api.get<AlertSettingsResponse>('/settings')

  return data
}
