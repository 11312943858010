import { Image } from 'antd'

import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import { formatCurrency } from '@/common'

interface MarketplaceDataDisplay {
  marketplace: 'vtex' | 'anymarket'
  price?: number
  stock?: {
    value?: number
    isLowStock?: boolean
  }
}

const MARKETPLACE_IMAGE = {
  vtex: {
    src: vtexLogo,
    width: 30,
    alt: 'VTEX Logo',
  },
  anymarket: {
    src: anymarketLogo,
    width: 40,
    alt: 'Anymarket Logo',
  },
}

export const MarketplaceDataDisplay = ({ marketplace, price, stock }: MarketplaceDataDisplay) => {
  const hasContent = Boolean(price || stock?.value)

  if (!hasContent) return null

  const logo = MARKETPLACE_IMAGE[marketplace]

  return (
    <S.Container>
      <Image src={logo.src} width={logo.width} alt={logo.alt} preview={false} />

      {price ? (
        <span>
          {formatCurrency({
            value: price,
            type: 'currency',
          })}
        </span>
      ) : null}

      {stock?.value ? (
        <S.StockContainer>
          Em estoque:
          <S.StockValue $isLowStock={stock?.isLowStock || false}>{stock?.value}</S.StockValue>
        </S.StockContainer>
      ) : null}
    </S.Container>
  )
}
