/**
 * Checks if the provided value is an empty object, array, string, or other types
 * @param value - The value to check
 * @returns boolean - True if empty, false otherwise
 */
export function isEmpty<T>(value: T): boolean {
  // Check for null or undefined
  if (value == null) {
    return true
  }

  // Check for strings
  if (typeof value === 'string') {
    return value.trim().length === 0
  }

  // Check for arrays
  if (Array.isArray(value)) {
    return value.length === 0
  }

  // Check for objects
  if (typeof value === 'object') {
    return Object.keys(value as object).length === 0
  }

  // For other types (number, boolean, etc.)
  return false
}
