import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Form, FormInstance, InputNumber, Skeleton } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { isEmpty } from '@/common'
import {
  alertQueries,
  AlertSettings,
  DEFAULT_SLA_DAYS,
  SLA,
  useCreateAlertSettingsMutation,
  useUpdateAlertSettingsMutation,
} from '@/features/alerts'
import { BgStatusTags } from '@/features/BG'
import { ProductStatus } from '@/features/BG/types'

interface StatusAlertFormProps {
  form: FormInstance<any>
  setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
  setIsMutating: React.Dispatch<React.SetStateAction<boolean>>
}

export const StatusAlertForm = ({
  form,
  setIsFormTouched,
  setIsMutating,
}: StatusAlertFormProps) => {
  const { data: alertSettings, isLoading } = useQuery(alertQueries.alertSettings())

  const createStatusAlertMutation = useCreateAlertSettingsMutation()
  const updateStatusAlertMutation = useUpdateAlertSettingsMutation()

  useEffect(() => {
    if (!isEmpty(alertSettings?.sla_days)) {
      form.setFieldsValue(alertSettings?.sla_days)
    }
  }, [alertSettings, form])

  function formatKeys(slaDays: SLA) {
    return Object.entries(slaDays).reduce((acc, [key, value]) => {
      if (key === key.toUpperCase()) {
        return {
          ...acc,
          [key]: Number(value),
        }
      }

      return acc
    }, {} as SLA)
  }

  const formatValues = (data: Record<string, number>): SLA => {
    return Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Math.max(1, Math.min(value, 99)),
      }),
      {} as SLA,
    )
  }

  const handleSubmit = async (formValues: SLA) => {
    try {
      setIsMutating(true)

      const isUpdating = !!alertSettings?.sla_days
      const mutation = isUpdating ? updateStatusAlertMutation : createStatusAlertMutation
      const configStatus = isUpdating ? 'atualizada' : 'criada'

      const payload: AlertSettings = {
        minimal_stock: form.getFieldValue('amount') || alertSettings?.stock.minimal_stock || 0,
        type_of_alert: form.getFieldValue('alert_type') || 'EMAIL',
        sla_days: formatValues(formatKeys(formValues)),
      }

      const promise = mutation.mutateAsync(payload)

      toast.promise(promise, {
        loading: 'Salvando configurações de Alertas...',
        error: 'Erro ao salvar configurações de Alertas',
        success: () => (
          <S.ToastContainer>
            <S.CircleOutlined />
            <div>
              Configuração <strong>{configStatus}</strong> com <strong>sucesso</strong>!
            </div>
          </S.ToastContainer>
        ),
      })

      await promise
    } catch (error) {
      console.error(error)
    } finally {
      setIsMutating(false)
    }
  }

  if (isLoading) return <Skeleton active />

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form}
      onFinish={handleSubmit}
      onValuesChange={() => setIsFormTouched(true)}
    >
      <S.Container>
        <S.TextContainer>
          <span>
            Defina os <strong>períodos (em dias)</strong> que os produtos poderão permanecer em cada
            um dos status abaixo.
          </span>

          <div>
            <S.RuleDescription>Mínimo: 1 dia. Máximo: 99 dias.</S.RuleDescription>
          </div>
        </S.TextContainer>

        {Object.keys(DEFAULT_SLA_DAYS).map((status) => {
          return (
            <S.FormRow key={status}>
              <Form.Item key={status} name={status}>
                <InputNumber type="number" min={1} max={99} keyboard={false} size="middle" />
              </Form.Item>

              <BgStatusTags status={status as ProductStatus} showDescription />
            </S.FormRow>
          )
        })}
      </S.Container>
    </Form>
  )
}
