import { SLA } from '../types'

export const DEFAULT_SLA_DAYS: SLA = {
  'EM RASCUNHO DO PRODUTO': 1,
  'PENDENTE DE ENVIO PARA AGÊNCIA': 1,
  'PENDENTE DE LIBERAÇÃO DA AGÊNCIA': 1,
  'PENDENTE DE CADASTRO NO PROTHEUS': 1,
  'EM RASCUNHO NO E-COMMERCE': 1,
  'PENDENTE DE PRECIFICAÇÃO': 1,
}
