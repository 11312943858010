import React, { useCallback, useEffect, useState } from 'react'
import { BsQuestionLg } from 'react-icons/bs'

import { LoadingOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Form, GetProp, Image, Radio, Spin, Tooltip, Upload, UploadFile, UploadProps } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { toast } from 'sonner'

import * as S from './styles'

import UploadImageSmall from '@/assets/upload-image-small.svg'
import { MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_IMAGE_COUNT } from '@/common'
import { getBase64 } from '@/common/utils/getBase64'
import { BaseTable } from '@/components'
import {
  BgFileUploadData,
  SkuFile,
  UploadStatus,
  useS3Url,
  validateFilesBeforeUpload,
} from '@/features/BG'

interface SkuFileProps {
  data: SkuFile[]
  isLoading?: boolean
  setImagePackData: React.Dispatch<React.SetStateAction<BgFileUploadData[]>>
  imagePackData: BgFileUploadData[]
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const ImagePackTable = ({
  data,
  isLoading = false,
  setImagePackData,
  imagePackData,
}: SkuFileProps) => {
  const form = Form.useFormInstance()
  const fetchS3Url = useS3Url()
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>()

  const [tableData, setTableData] = useState(data)
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState(false)

  useEffect(() => {
    const selectedRadio = tableData.find((item) => item.main === true)

    setCurrentlySelectedRow(selectedRadio?.order as any)
    form.setFieldValue('showcase', tableData)
  }, [tableData, form])

  const columns: ColumnsType<any> = [
    {
      title: 'Vitrine',
      dataIndex: 'image',
      width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <Radio
            onClick={() => handleRowSelection(record)}
            checked={currentlySelectedRow === record.order}
          />
        )
      },
    },
    {
      title: 'Label',
      dataIndex: 'label',
      render: (_, record: SkuFile) => {
        return (
          <S.ShowcaseContainer>
            <Image preview={false} src={record?.filename_url as string} />
            {record.name}
          </S.ShowcaseContainer>
        )
      },
    },
    {
      title: () => {
        return (
          <S.TooltipContainer>
            <S.InputName>Ordenar</S.InputName>

            <Tooltip title="Clique nos pontos e arraste para reordenar as imagens.">
              <S.TooltipIcon>
                <BsQuestionLg />
              </S.TooltipIcon>
            </Tooltip>
          </S.TooltipContainer>
        )
      },
      dataIndex: '',
      key: 'sort',
      width: 100,
      align: 'center',
    },
    {
      title: 'Ações',
      dataIndex: '',
      width: 100,
      render: (_, record: SkuFile) => (
        <S.DeleteImageButton onClick={() => handleDeleteFile(record)} />
      ),
      align: 'center',
    },
  ]

  const handleRowSelection = (selectedRow: any) => {
    // Helper Function to update the 'main' attribute in each table data item
    const updateMainPropertyForAllItems = (shouldBeMain: boolean) => {
      tableData.forEach((item) => {
        item.main = item.order === selectedRow.order ? shouldBeMain : false
      })
    }

    if (currentlySelectedRow === selectedRow.order) {
      updateMainPropertyForAllItems(false)
      setCurrentlySelectedRow(false)
    } else {
      updateMainPropertyForAllItems(true)
      setCurrentlySelectedRow(selectedRow.order)
    }

    // Always update 'showcase' field value regardless of the condition
    form.setFieldValue('showcase', tableData)
  }

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <S.DraggableIcon
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              ),
            })
          }
          return child
        })}
      </tr>
    )
  }

  const Summary = () => {
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <S.UploadContainer>
            <Upload
              action=""
              multiple
              showUploadList={false}
              beforeUpload={(file, fileList) => handleUploadFile(file, fileList)}
            >
              {uploadStatus === 'uploading' ? (
                <S.LoadingStatusContainer>
                  <Spin indicator={<LoadingOutlined size={24} spin />} />
                </S.LoadingStatusContainer>
              ) : (
                <img src={UploadImageSmall} alt="upload bg" />
              )}
            </Upload>
          </S.UploadContainer>
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  const handleDeleteFile = (file: SkuFile) => {
    const filteredFiles = tableData
      .filter((f) => file.order.toString() !== f.order.toString())
      .map((f, index) => ({ ...f, order: index }))

    if (filteredFiles.length && !filteredFiles.some((f) => f.main)) {
      filteredFiles[0] = { ...filteredFiles[0], main: true }
    }

    const excludedFiles = form.getFieldValue('excludedFiles') || []

    form.setFieldValue('excludedFiles', [...excludedFiles, file.s3_filename])

    setTableData(filteredFiles)
    setImagePackData(imagePackData.filter((elem) => elem.filename !== file.s3_filename))
  }

  const handleUploadFile = useCallback(
    async (file: File, fileList: UploadFile[]) => {
      if (uploadStatus === 'uploading') return

      const validationResult = validateFilesBeforeUpload(
        file,
        fileList,
        imagePackData,
        MAX_UPLOAD_IMAGE_COUNT,
        MAX_UPLOAD_FILE_SIZE,
      )

      if (!validationResult) return Upload.LIST_IGNORE

      setUploadStatus('uploading')

      try {
        const result = await fetchS3Url(file)

        const preview = await getBase64(file as FileType)

        setImagePackData((oldData) => [...oldData, result])

        const fileIndex = fileList.findIndex((elem) => elem.name === file.name)

        setTableData((oldData) =>
          [
            ...oldData,
            {
              filename_url: preview,
              main: false,
              is_cover_bg_image: false,
              name: result.file.name,
              s3_filename: result.filename,
              order: tableData.length + fileIndex,
            },
          ].sort((a, b) => Number(a.order) - Number(b.order)),
        )

        toast.success(
          <S.ToastContainer>
            <S.CircleOutlined />
            <div>
              {file.name} <span style={{ fontWeight: 'bold' }}>carregado</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>
            </div>
          </S.ToastContainer>,

          {
            position: 'bottom-right',
            className: 'toast-sucess',
          },
        )
      } catch (error) {
        console.error('Error uploading file:', error)
        toast.error(`Erro ao carregar arquivo ${file.name}.`)
      } finally {
        setUploadStatus('done')
      }
    },
    [fetchS3Url, imagePackData, setImagePackData, tableData.length, uploadStatus],
  )

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active?.id !== over?.id) {
      const tableLine = (previous: SkuFile[]) => {
        const activeIndex = previous.findIndex((i) => i.order === active.id)
        const overIndex = previous.findIndex((i) => i.order === over?.id)
        return arrayMove(previous, activeIndex, overIndex)
      }

      const newTableData = tableLine(tableData)
      const sortedTableData = newTableData
        .map((item, i) => ({
          ...item,
          order: i.toString(),
        }))
        .sort((a, b) => Number(a.order) - Number(b.order))

      form.setFieldValue('showcase', sortedTableData)
      setTableData(sortedTableData)
    }
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
      <SortableContext items={tableData.map((i) => i.order)} strategy={verticalListSortingStrategy}>
        <BaseTable
          summary={Summary}
          components={{
            body: {
              row: Row,
            },
          }}
          sticky
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={isLoading}
          rowKey="order"
        />
      </SortableContext>
    </DndContext>
  )
}
