import styled from 'styled-components'

type StockValueProps = {
  $isLowStock: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 32px;
    font-weight: 700;
    color: #434343;
  }
`

export const StockContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6c6c6c;
`

export const StockValue = styled.div<StockValueProps>`
  color: ${(props) => (props.$isLowStock ? '#FF4D4D' : '#4A91FD')};
  font-weight: 700;
`
