import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DEFAULT_SLA_DAYS } from '../constants'
import { alertQueries } from '../queries'
import { AlertSettings } from '../types'

import { api } from '@/services'

export const useUpdateAlertSettingsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: AlertSettings) => {
      return api.put('/settings', {
        ...data,
        sla_days: data.sla_days || DEFAULT_SLA_DAYS,
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: alertQueries.alertSettings().queryKey }),
  })
}
